





















































































import {
  defineComponent,
  onBeforeUnmount,
  onMounted
} from "@vue/composition-api"
import { KEY_SPACE, KEY_LEFT, KEY_RIGHT } from "keycode-js"
import { child, serverTimestamp, set, update } from "firebase/database"

import { Module } from "../GroupTeams/Common/Games/JeopardAI/enums"

import useJeopardAiStateRef from "../GroupTeams/Common/Games/JeopardAI/use/useJeopardAiStateRef"
import useJeopardAiRootRef from "../GroupTeams/Common/Games/JeopardAI/use/useJeopardAiRootRef"
import useJeopardAiResults from "../GroupTeams/Common/Games/JeopardAI/use/useJeopardAiResults"
import useJeopardAiRound from "../GroupTeams/Common/Games/JeopardAI/use/useJeopardAiRound"
import useJeopardAiModules from "../GroupTeams/Common/Games/JeopardAI/use/useJeopardAiModules"
import useTeamsRef from "../GroupTeams/Common/Games/JeopardAI/use/useTeamsRef"
import useCurrentTeamIdRef from "../GroupTeams/Common/Games/JeopardAI/use/useCurrentTeamIdRef"
import useJeopardAiNavigation from "../GroupTeams/Common/Games/JeopardAI/use/useJeopardAiNavigation"

import TeamTag from "@/components/GroupTeams/Common/TeamTag.vue"
import JeopardAiTeamResultCard from "@/components/GroupTeams/Common/JeopardAiTeamResultCard.vue"
import JeopardAiChallengeResultCard from "@/components/GroupTeams/Common/JeopardAiChallengeResultCard.vue"

import { INPUT_TYPES } from "../GroupTeams/Common/Games/JeopardAI/constants"

const FLIPPED_KEYWORD = "results"

export default defineComponent({
  name: "JeopardAiTeamResults",
  components: {
    JeopardAiChallengeResultCard,
    JeopardAiTeamResultCard,
    TeamTag
  },
  setup() {
    const {
      isViewerHostLike,
      teams: sorted,
      challenges,
      state,
      isTeamFlipped,
      module
    } = useJeopardAiResults()

    const { next: advance } = useJeopardAiNavigation()
    const { round } = useJeopardAiRound()
    const { ref: rootRef } = useJeopardAiRootRef(round)
    const { ref: stateRef } = useJeopardAiStateRef(round)
    const { ref: teamsRef } = useTeamsRef()
    const { ref: currentTeamIdRef } = useCurrentTeamIdRef()
    const { modules } = useJeopardAiModules()

    async function next() {
      if (module.value === Module.RESULTS) {
        const team = [...sorted.value]
          .reverse()
          .find(team => !isTeamFlipped.value(team))

        if (team != null) {
          open(team)
          return
        }
      }

      if (module.value === Module.WRAPUP) {
        const team = [...challenges.value]
          .reverse()
          .find(team => !isTeamFlipped.value(team))

        if (team != null) {
          open(team)
          return
        }

        advance()

        return
      }

      const index = modules.value.findIndex(m => module.value === m) ?? 0
      const value = modules.value[index + 1]

      if (value == null) return

      await update(rootRef.value, {
        [`state/module`]: value,
        [`state/timestamp`]: serverTimestamp()
      })
    }

    function prev() {
      const index = modules.value.findIndex(m => module.value === m) ?? 0
      let value = modules.value[index - 1]
      if (value == null) return
      if (state.value?.module === Module.FINALE) value = Module.QUESTIONS

      update(stateRef.value, {
        module: value,
        questionId: null,
        timestamp: serverTimestamp()
      })
    }

    function handleKeyPress(event) {
      if (INPUT_TYPES.includes(event.target?.type)) return

      if (event.keyCode === KEY_SPACE || event.keyCode === KEY_RIGHT) {
        next()
        return
      }

      if (event.keyCode === KEY_LEFT) {
        prev()
        return
      }
    }

    function reset() {
      currentTeamIdRef.value.set(null)
      set(child(stateRef.value, "flipped"), null)
      teamsRef.value.update(
        this.teams.reduce((acc, val) => {
          acc[`${val.id}/flippedState`] = null
          return acc
        }, {})
      )
    }

    function open(team) {
      currentTeamIdRef.value.set(team.id)
      set(child(stateRef.value, `flipped/${team.id}`), true)
      teamsRef.value.child(`${team.id}/flippedState`).set(FLIPPED_KEYWORD)
    }

    onMounted(() => {
      document.addEventListener("keydown", handleKeyPress)
    })

    onBeforeUnmount(() => {
      document.removeEventListener("keydown", handleKeyPress)
    })

    return { reset, open, isViewerHostLike, teams: sorted, challenges, state }
  }
})
