var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jeopardai-team-results" },
    [
      _c(
        "transition",
        {
          attrs: { name: "jeopardai-team-results__transition", mode: "out-in" },
        },
        [
          _vm.challenges.length > 0
            ? _c(
                "div",
                {
                  key: "wrapup",
                  staticClass: "jeopardai-team-results__content",
                },
                _vm._l(_vm.challenges, function (team, index) {
                  var _obj
                  return _c(
                    "div",
                    {
                      key: team.id + "__card",
                      staticClass: "jeopardai-team-results__item",
                    },
                    [
                      _c(
                        "transition",
                        {
                          attrs: {
                            name: "jeopardai-team-results__tag-transition",
                            mode: "out-in",
                          },
                        },
                        [
                          team.showTag
                            ? _c("TeamTag", {
                                key: team.id + "__tag",
                                staticClass: "jeopardai-team-results__tag",
                                class: {
                                  "jeopardai-team-results__tag--active":
                                    team.active,
                                },
                                attrs: {
                                  team: team,
                                  pinnable: false,
                                  score: false,
                                  mode: "play",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("JeopardAiChallengeResultCard", {
                        staticClass: "jeopardai-team-results__card",
                        class:
                          ((_obj = {}),
                          (_obj["jeopardai-team-results__card--clickable"] =
                            _vm.isViewerHostLike),
                          _obj),
                        attrs: {
                          team: team,
                          position: index + 1,
                          flipped: team.flipped,
                          current: team.active,
                        },
                        nativeOn: {
                          click: [
                            function ($event) {
                              if (
                                $event.ctrlKey ||
                                $event.shiftKey ||
                                $event.altKey ||
                                $event.metaKey
                              ) {
                                return null
                              }
                              _vm.isViewerHostLike && _vm.open(team)
                            },
                            function ($event) {
                              if (!$event.altKey) {
                                return null
                              }
                              _vm.isViewerHostLike && _vm.reset()
                            },
                          ],
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _c(
                "div",
                {
                  key: "results",
                  staticClass: "jeopardai-team-results__content",
                },
                _vm._l(_vm.teams, function (team, index) {
                  var _obj
                  return _c(
                    "div",
                    {
                      key: team.id + "__card",
                      staticClass: "jeopardai-team-results__item",
                    },
                    [
                      _c(
                        "transition",
                        {
                          attrs: {
                            name: "jeopardai-team-results__tag-transition",
                            mode: "out-in",
                          },
                        },
                        [
                          team.showTag
                            ? _c("TeamTag", {
                                key: team.id + "__tag",
                                staticClass: "jeopardai-team-results__tag",
                                class: {
                                  "jeopardai-team-results__tag--active":
                                    team.active,
                                },
                                attrs: {
                                  team: team,
                                  pinnable: false,
                                  score: false,
                                  mode: "play",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("JeopardAiTeamResultCard", {
                        staticClass: "jeopardai-team-results__card",
                        class:
                          ((_obj = {}),
                          (_obj["jeopardai-team-results__card--clickable"] =
                            _vm.isViewerHostLike),
                          _obj),
                        attrs: {
                          team: team,
                          position: index + 1,
                          flipped: team.flipped,
                          current: team.active,
                        },
                        nativeOn: {
                          click: [
                            function ($event) {
                              if (
                                $event.ctrlKey ||
                                $event.shiftKey ||
                                $event.altKey ||
                                $event.metaKey
                              ) {
                                return null
                              }
                              _vm.isViewerHostLike && _vm.open(team)
                            },
                            function ($event) {
                              if (!$event.altKey) {
                                return null
                              }
                              _vm.isViewerHostLike && _vm.reset()
                            },
                          ],
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }